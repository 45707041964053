import { useQuery } from '@apollo/client';
import { COUNT_ORDERING } from '@fingo/lib/graphql';
import { useSelectedCompany } from '@fingo/lib/hooks';

const useCountOrdering = () => {
  const selectedCompany = useSelectedCompany();
  const { data } = useQuery(
    COUNT_ORDERING,
    {
      variables: {
        companyId: selectedCompany?.id,
      },
      skip: !selectedCompany,
    },
  );
  return {
    available: data?.available.totalCount,
    offers: data?.offers.totalCount,
    activeOperations: data?.activeOperations.totalCount,
    finishedOperations: data?.finishedOperations.totalCount,
  };
};

export default useCountOrdering;
