import { useMemo } from 'react';
import { calculateCommission, calculateInvoiceAmounts } from '@fingo/lib/helpers/invoice-amounts-calculation';
import useGetMonthlyRate from '@fingo/lib/hooks/useGetMonthlyRate';
import { formatMoney, formatMoneyWithSign } from '@fingo/lib/helpers';
import { useBooleanState, useTextFieldInput } from '@fingo/lib/hooks';

const useSimulationAmounts = (client, debtor, amount, days) => {
  const [monthlyRate] = useGetMonthlyRate(client, debtor);
  const [isFlipped, toggleIsFlipped] = useBooleanState(false);
  const [cellphone, setCellphone] = useTextFieldInput('');
  const [mail, setMail] = useTextFieldInput('');

  const rates = useMemo(() => ({
    days,
    amountWithIva: { amount },
    defaultRate: monthlyRate?.defaultRate,
    retentionRate: monthlyRate?.retentionRate,
    monthlyRate: monthlyRate?.monthlyRate,
  }), [days, amount, monthlyRate]);

  const {
    retentionAmount,
    amountMinusInterests,
    priceDifference,
  } = useMemo(() => (monthlyRate ? calculateInvoiceAmounts(rates) : {}), [rates, monthlyRate]);

  const commission = useMemo(() => (monthlyRate
    ? calculateCommission(monthlyRate.commissionRules.rules, amount) : 0), [monthlyRate, amount]);

  const summary = useMemo(() => [
    { label: 'Cantidad de facturas', value: monthlyRate ? 1 : 0, format: formatMoney },
    { label: 'Tasa', value: monthlyRate?.monthlyRate || 0 },
    { label: 'Monto total', value: monthlyRate ? amount : 0, format: formatMoneyWithSign },
    { label: 'Diferencia de precio', value: priceDifference || 0, format: formatMoneyWithSign },
    { label: 'Comisión', value: commission || 0, format: formatMoneyWithSign },
    { label: 'Costos totales', value: (commission || 0) + (priceDifference || 0), format: formatMoneyWithSign },
    { label: 'Monto retenido', value: retentionAmount || 0, format: formatMoneyWithSign },
    { label: 'Monto a depositar', value: (amountMinusInterests || 0) - (commission || 0), format: formatMoneyWithSign },
  ], [monthlyRate, amount, priceDifference, commission, retentionAmount, amountMinusInterests]);

  return {
    isFlipped,
    toggleIsFlipped,
    cellphone,
    setCellphone,
    mail,
    setMail,
    summary,
  };
};

export default useSimulationAmounts;
