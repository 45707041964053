/* eslint-disable eqeqeq */
import React from 'react';
import { FingoPaper, Sidebar } from '@fingo/lib/components/layout';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import RequestQuoteOutlined from '@mui/icons-material/RequestQuoteOutlined';
import { Redirect } from 'react-router-dom';
import ConfirmingUploadFunding from '@fingo/lib/views/confirming/columns/views/ConfirmingUploadFunding';
import { MyPurchases } from '../views/App/purchase/purchasesRoutes';
import { MyProviders } from '../views/App/purchase/providersRoutes';
import { isRouteDisabled } from '../helpers/routes';
import { ConfirmingUpload, PendingConfirming, CurrentConfirming, HistoricalConfirming } from '../views/App/purchase/confirmingRoutes';
import ConfirmingDashboard from '../views/App/purchase/confirmingRoutes/confirmingHome/ConfirmingDashboard';

const providersTabs = [
  {
    id: 'my-providers',
    label: 'Proveedores',
    path: 'contacts',
    component: <MyProviders />,
    disabled: false,
    show: true,
  },
];

const purchaseTabs = [
  {
    id: 'my-purchases',
    label: 'Cuentas por pagar',
    path: 'my-purchases',
    component: <MyPurchases />,
    disabled: false,
    show: true,
  },
];

const uploadConfirmingTabs = [
  {
    id: 'confirming-portfolio',
    label: 'Cartera de confirming',
    path: 'confirming-portfolio',
    component: <ConfirmingUpload />,
    disabled: false,
    show: true,
  },
];

const payrollTabs = [
  {
    id: 'pending',
    label: 'En curso',
    path: 'pending',
    component: <PendingConfirming />,
    disabled: false,
    show: true,
  },
  {
    id: 'current',
    label: 'Vigentes',
    path: 'current',
    component: <CurrentConfirming />,
    disabled: false,
    show: true,
  },
  {
    id: 'historical',
    label: 'Históricas',
    path: 'historical',
    component: <HistoricalConfirming />,
    disabled: false,
    show: true,
  },
];

const homeTabs = [
  {
    id: 'home',
    label: 'Home',
    path: 'home',
    component: <ConfirmingDashboard />,
    disabled: false,
    show: true,
  },
];

const purchasesSubRoutes = (user, isCountryAvailable) => [
  {
    id: 'purchases/confirming',
    label: 'Confirming',
    path: 'purchases/confirming',
    icon: <MonetizationOn />,
    disabled: !(user?.isSuperuser || user?.id == 775646),
    show: (user?.isSuperuser || user?.id == 775646),
    component: <Redirect to={isCountryAvailable ? '/app/purchases/confirming/home' : '/app/purchases/payable'} />,
    showInSidebar: isCountryAvailable,
  }, {
    id: 'confirming',
    path: 'confirming',
    disabled: !(user?.isSuperuser || user?.id == 775646),
    show: (user?.isSuperuser || user?.id == 775646),
    showInSidebar: isCountryAvailable,

    component: [{
      id: 'home',
      label: 'Home',
      path: 'home',
      icon: <></>,
      component: <FingoPaper tabs={homeTabs} />,
      disabled: isRouteDisabled(user),
      show: isCountryAvailable,
      subRoutes: [],
    },
    {
      id: 'funding',
      label: 'Financiar nóminas',
      path: 'funding',
      icon: <></>,
      component: <FingoPaper tabs={uploadConfirmingTabs} />,
      disabled: isRouteDisabled(user),
      show: isCountryAvailable,
      subRoutes: [],
    }, {
      id: 'summary',
      label: 'Nóminas enviadas',
      path: 'summary',
      icon: <></>,
      component: <FingoPaper tabs={payrollTabs} />,
      disabled: isRouteDisabled(user),
      show: isCountryAvailable,
      subRoutes: [],
    }],
  },
  {
    id: 'purchases-side-payable',
    label: 'Cuentas por pagar',
    path: 'payable',
    icon: <RequestQuoteOutlined />,
    component: <FingoPaper tabs={purchaseTabs} />,
    disabled: isRouteDisabled(user),
    showInSidebar: true,
    show: true,
    subRoutes: [],
  },
  {
    id: 'purchases-side-providers',
    label: 'Proveedores',
    path: 'providers',
    icon: <LocalShippingOutlined />,
    component: <FingoPaper tabs={providersTabs} />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
    subRoutes: [],
  },
];

export default (user, showTyc, country) => {
  const isCountryAvailable = ['Chile'].includes(country?.name);
  return [
    {
      id: 'nav-purchases',
      label: 'Mis compras',
      path: '/app/purchases',
      icon: null,
      component: <Sidebar options={purchasesSubRoutes(user, isCountryAvailable)} />,
      disabled: isRouteDisabled(user),
      show: user && !showTyc,
      showInNavbar: true,
      subRoutes: purchasesSubRoutes(user, isCountryAvailable),
    },
    {
      id: 'confirming-operation',
      label: 'Operación de Confirming',
      path: '/app/payroll-profile',
      component: <ConfirmingUploadFunding />,
      disabled: !(user?.isSuperuser || user?.id == 775646),
      show: (user?.isSuperuser || user?.id == 775646),
      showInNavbar: false,
    },
  ];
};
