import React from 'react';
import { Sidebar } from '@fingo/lib/components/layout';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MyNotifications from '../views/App/notifications/MyNotifications';
import Notifications from '../views/App/notifications/Notifications';

const notificationsSubRoutes = [
  {
    id: 'notifications',
    label: 'Notificaciones',
    path: 'notifications',
    icon: <DescriptionOutlinedIcon />,
    component: <Notifications />,
    disabled: false,
    show: true,
  },
];

export const notificationsRoutes = [
  {
    id: 'notifications',
    label: 'Notificaciones',
    path: '/app/notifications',
    component: <Sidebar goBack options={notificationsSubRoutes} />,
    disabled: false,
    show: true,
    showInNavbar: false,
    subRoutes: notificationsSubRoutes,
  },
];

export const notificationTabs = [
  {
    id: 'my-notifications',
    label: 'Mis Notificaciones',
    path: 'my-notifications',
    component: <MyNotifications />,
    disabled: false,
    show: true,
  },
];

export default notificationsRoutes;
