import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Sidebar } from '@fingo/lib/components/layout';
import Analytics from '../views/App/analytics/Analytics';
import Tracker from '../views/App/Sophia';
import { isRouteDisabled } from '../helpers/routes';

const analyticsSubRoutes = (user) => [
  {
    id: 'analytics-side-finance',
    label: 'Financiero',
    path: 'finance',
    icon: <DescriptionOutlinedIcon />,
    component: <Analytics />,
    disabled: isRouteDisabled(user),
    show: true,
    showInSidebar: true,
  },
];

export const analyticsRoutes = (user, showTyc, country) => [
  {
    id: 'nav-analytics',
    label: 'Analytics',
    path: '/app/analytics',
    component: <Sidebar options={analyticsSubRoutes(user)} />,
    disabled: isRouteDisabled(user),
    show: user && !showTyc && country?.name !== 'Mexico',
    showInNavbar: true,
    subRoutes: analyticsSubRoutes(user),
  },
];

export const analyticsTabs = [
  {
    id: 'tracker',
    label: 'Financiero',
    path: 'tracker',
    component: <Tracker />,
    disabled: false,
    show: true,
  },
];
