import { useGetUser } from '@fingo/lib/hooks';

const useShowingTyC = () => {
  const user = useGetUser();
  if (!user) return false;
  const { selectedCompany } = user;
  return Boolean(selectedCompany && !selectedCompany.hasLendingAcceptedterms && !user.demo
    && !user.isStaff);
};

export default useShowingTyC;
