import { useMemo } from 'react';
import FingoImage from '@fingo/lib/assets/icons/fingo_image.png';
import { useSelectedCompany } from '@fingo/lib/hooks';

const useCessionTypeOptions = (selectedProvider, allDocumentsHaveXML) => {
  const company = useSelectedCompany();
  const { hasDigitalCertificate, invoiceProviderCredentials = [] } = company;

  const hasInvoiceProviderCredentials = useMemo(() => {
    if (!selectedProvider || !selectedProvider.id) return false;

    return invoiceProviderCredentials.some(
      (credential) => credential.credentialProvider.id === selectedProvider.id,
    );
  }, [invoiceProviderCredentials, selectedProvider]);

  const options = useMemo(() => {
    if (!selectedProvider) {
      return [];
    }

    const { name, iconUrl, integrated } = selectedProvider;

    const subItems = [
      {
        id: 1,
        text: 'Subir Certificado Digital',
        checked: hasDigitalCertificate,
      },
    ];

    if (name !== 'MiPyme') {
      if (integrated) {
        subItems.push({
          id: 2,
          text: 'Ingresar credenciales de tu facturador',
          checked: hasInvoiceProviderCredentials,
        });
      } else {
        subItems.push({
          id: 2,
          text: 'Subir XML de tus facturas',
          checked: allDocumentsHaveXML,
        });
      }
    }

    return [
      {
        value: 'internal',
        label: 'Cede desde Fingo',
        src: FingoImage,
        description: 'Hacemos la cesión por ti. Solo necesitas:',
        recommended: true,
        subItems,
        footer:
          '(Tus credenciales están encriptadas, siempre estarán seguras con nosotros).',
      },
      {
        value: 'external',
        label: 'Cede desde tu facturador',
        src: iconUrl,
        description: 'Cesión manual desde tu facturador',
      },
    ];
  }, [selectedProvider, hasDigitalCertificate, hasInvoiceProviderCredentials]);

  return options;
};

export default useCessionTypeOptions;
