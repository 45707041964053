import AccountBalanceSharp from '@mui/icons-material/AccountBalanceSharp';
import BadgeOutlined from '@mui/icons-material/BadgeOutlined';
import Business from '@mui/icons-material/Business';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';
import { formatDayMonthYear, formatRut, hasAllRequiredDocuments,
  hasAllOperationalDocuments } from '@fingo/lib/helpers';
import CredentialProviderDialog from '../features/profile/components/dialogs/CredentialProviderDialog';
import BankAccountsDialog from '../features/profile/components/dialogs/BankAccountsDialog';
import UploadDigitalCertificateDialog from '../features/profile/components/dialogs/UploadDigitalCertificateDialog';
import SupplierPortalDialog from '../features/profile/components/dialogs/SupplierPortalDialog';
import UploadCreditLineDocuments from '../components/dialogs/UploadCreditLineDocuments';
import UploadOperationalDocuments from '../components/dialogs/UploadOperationalDocuments';

const formatInvoiceProviderUsername = (invoiceProvider) => {
  if (invoiceProvider?.authenticationType?.name === 'CERTIFICATE') {
    return { title: 'Certificado:', value: invoiceProvider?.certificate?.name || 'N/D' };
  }
  return { title: 'Rut:', value: formatRut(invoiceProvider?.username) || 'N/D' };
};

export const getPersonalInformation = () => ({
  id: 'personal-information',
  title: 'Información personal',
  isOk: true,
  disabled: true,
  showInProgressBanner: true,
  showInCredentials: false,
});

export const getSiiCredentials = (company, nationalTaxService) => {
  const { hasSiiCredentials = null, siiCredentials = [] } = company;
  const currentSiiCredentials = siiCredentials.at(-1);
  const credentials = hasSiiCredentials
    ? [
      {
        key: 'Rut empresa:',
        value: company.masterEntity.displayNationalIdentifier || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentSiiCredentials.updatedAt),
      },
    ]
    : [];
  return {
    id: 'sii-credentials',
    title: `Credenciales ${nationalTaxService}`,
    subtitle: `Ingresa las credenciales del ${nationalTaxService} de tu empresa de forma segura, 
      para sincronizar con Fingo la información de tus facturas y cesiones.`,
    isOk: hasSiiCredentials,
    avoidFingoDialog: true,
    DialogComponent: () => null,
    credentials,
    errorMessage: `Sin credenciales ${nationalTaxService}`,
    showInProgressBanner: true,
    showInCredentials: true,
  };
};

export const getInvoiceProviderCredentials = (company, nationalTaxService, country) => {
  const { hasInvoiceProviderCredentials = null, invoiceProviderCredentials = [] } = company;
  const currentInvoiceProviderCredentials = invoiceProviderCredentials.at(-1);
  const credentials = hasInvoiceProviderCredentials
    ? [
      {
        key: 'Facturador:',
        value: currentInvoiceProviderCredentials.credentialProvider.name || 'N/D',
      },
      formatInvoiceProviderUsername(currentInvoiceProviderCredentials),
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentInvoiceProviderCredentials.updatedAt),
      },
    ]
    : [];
  return {
    id: 'invoice-provider',
    title: 'Credenciales del facturador',
    shortTitle: 'Credenciales facturador',
    dialogTitle: 'Ingresa tus credenciales del facturador',
    subtitle: 'Elige tu facturador y sube tus credenciales para realizar tus cesiones por dentro de la plataforma, y que así no tengas que salir de Fingo.',
    isOk: hasInvoiceProviderCredentials,
    disabled: !company?.hasSiiCredentials && !hasInvoiceProviderCredentials,
    disabledTooltipText: `Primero debes ingresar tus credenciales del ${nationalTaxService}`,
    DialogComponent: CredentialProviderDialog,
    icon: BadgeOutlined,
    credentials,
    errorMessage: 'Sin credenciales del facturador',
    showInProgressBanner: ['Chile'].includes(country.name),
    showInCredentials: ['Chile'].includes(country.name),
  };
};

export const getDigitalCretificateCredentials = (company, nationalTaxService, country) => {
  const { hasDigitalCertificate = null, currentDigitalCertificate = null } = company;
  const hasExpirationDate = !!currentDigitalCertificate?.expireDatetime;
  const credentials = hasDigitalCertificate
    ? [
      {
        key: 'Certificado:',
        value: currentDigitalCertificate.certificate?.name || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentDigitalCertificate.updatedAt),
      },
      hasExpirationDate && {
        title: 'Válido hasta el:',
        value: formatDayMonthYear(currentDigitalCertificate.expireDatetime) || 'N/D',
      },
    ]
    : [];
  return {
    id: 'digital-certificate',
    title: 'Certificado digital',
    dialogTitle: 'Administra tu certificado digital',
    subtitle: `Carga aquí el certificado digital del Representante Legal de tu 
      empresa para que puedas ceder facturas y contar con más seguridad en las operaciones.`,
    isOk: company?.hasDigitalCertificate,
    disabled: !company?.hasSiiCredentials && !company?.hasDigitalCertificate,
    disabledTooltipText: `Primero debes ingresar tus credenciales del ${nationalTaxService}`,
    DialogComponent: UploadDigitalCertificateDialog,
    icon: TextSnippetOutlined,
    credentials,
    errorMessage: 'Sin certificado digital',
    showInProgressBanner: ['Chile'].includes(country.name),
    showInCredentials: ['Chile'].includes(country.name),
  };
};

export const getBankAccountCredentials = (company, nationalTaxService) => {
  const { hasBankAccount = null, bankAccounts = [] } = company;
  const currentBankAccountCredentials = bankAccounts.filter((a) => a?.active).at(-1);
  const credentials = hasBankAccount
    ? [
      {
        key: 'Banco:',
        value: currentBankAccountCredentials?.bankName || 'N/D',
      },
      {
        key: 'Nro cuenta:',
        value: currentBankAccountCredentials?.accountNumber || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentBankAccountCredentials?.updatedAt) || 'N/D',
      },
    ]
    : [];

  return {
    id: 'bank-account',
    title: 'Cuentas bancarias',
    dialogTitle: 'Administra tus cuentas bancarias',
    subtitle: `Ingresa tus datos bancarios para que podamos depositarte. Puedes 
      ingresar múltiples cuentas y seleccionar la que más te acomode al momento de girar.`,
    isOk: company?.hasBankAccount,
    disabled: !company?.hasSiiCredentials && !company?.hasBankAccount,
    disabledTooltipText: `Primero debes ingresar tus credenciales del ${nationalTaxService}`,
    DialogComponent: BankAccountsDialog,
    icon: AccountBalanceSharp,
    credentials,
    errorMessage: 'Sin cuentas bancarias',
    showInProgressBanner: true,
    showInCredentials: true,
  };
};

export const getSupplierPortalCredentials = (company, country) => {
  const { hasSupplierPortalCredentials = null, supplierPortalCredentials = [] } = company;
  const currentSupplierPortalCredentials = supplierPortalCredentials.at(-1);
  const credentials = hasSupplierPortalCredentials
    ? [
      {
        key: 'Portal:',
        value: currentSupplierPortalCredentials.credentialProvider.name || 'N/D',
      },
      {
        key: 'Usuario:',
        value: currentSupplierPortalCredentials.secondUsername || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentSupplierPortalCredentials.updatedAt) || 'N/D',
      },
    ]
    : [];

  return {
    id: 'supplier-portal',
    title: 'Portal de proveedores',
    dialogTitle: 'Ingresa tus credenciales del portal de proveedores',
    subtitle: `Sube las credenciales de tus portales de proveedores para obtener el estado de 
      pago de tus facturas al día y descubre todas las nuevas y útiles funcionalidades que tenemos para ti.`,
    isOk: hasSupplierPortalCredentials,
    DialogComponent: SupplierPortalDialog,
    icon: Business,
    credentials,
    errorMessage: 'Sin portal de proveedores',
    showInProgressBanner: false,
    showInCredentials: ['Chile'].includes(country.name),
  };
};

export const getCreditLineDocumentCredentials = (company, nationalTaxService, country) => ({
  id: 'credit-line-documents',
  title: 'Carga de documentos para evaluación',
  shortTitle: 'Documentos requeridos',
  dialogTitle: 'Carga de documentos para evaluación',
  subtitle: null,
  isOk: hasAllRequiredDocuments(company),
  disabled: !company?.hasSiiCredentials,
  disabledTooltipText: `Primero debes ingresar tus credenciales del ${nationalTaxService}`,
  DialogComponent: UploadCreditLineDocuments,
  icon: null,
  credentials: [],
  errorMessage: 'No tienes todos los documentos requeridos',
  showInProgressBanner: ['Mexico'].includes(country.name),
  showInCredentials: ['Mexico'].includes(country.name),
});

export const getOperationalDocumentsCredentials = (company, nationalTaxService, country) => ({
  id: 'operational-line-documents',
  title: 'Carga de documentos operacionales',
  shortTitle: 'Documentos operacionales',
  dialogTitle: 'Carga de documentos operacionales',
  subtitle: null,
  isOk: hasAllOperationalDocuments(company),
  disabled: !company?.hasSiiCredentials,
  disabledTooltipText: `Primero debes ingresar tus credenciales del ${nationalTaxService}`,
  DialogComponent: UploadOperationalDocuments,
  icon: null,
  credentials: [],
  errorMessage: 'No tienes todos los documentos requeridos',
  showInProgressBanner: false,
  showInCredentials: ['Mexico'].includes(country.name),
});
