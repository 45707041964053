import {
  LokalCredentialsFlipCard,
  LandingRegisterForm,
  SimulatorRegister,
  SimulatorRegisterVideo,
  SimulatorRegisterCaptcha,
  Simulator,
  KobrosLandingRegisterForm,
  LandingNPS,
  EvaluateInvoices,
  Churn,
} from '../components/pilots';
import LandingRegisterTeam from '../components/pilots/LandingRegisterTeam';
import LandingRegisterWhatsappEvaluation from '../components/pilots/LandingRegisterWhatsappEvaluation';
import LandingRegisterWithClientImage from '../components/pilots/LandingRegisterWithClientImage';
import LandingRegisterWithTimelineSteps from '../components/pilots/LandingRegisterWithTimelineSteps';
import LandingRegisterWithPicture from '../components/pilots/LanginRegisterWithPicture';
import CollectionRegister from '../features/collection/pages/CollectionRegister';

const pilotRutes = [
  {
    path: '/landing-register',
    component: LandingRegisterForm,
  },
  {
    path: '/landing-register-steps',
    component: LandingRegisterWithTimelineSteps,
  },
  {
    path: '/landing-register-whatsapp',
    component: LandingRegisterWhatsappEvaluation,
  },
  {
    path: '/landing-register-team',
    component: LandingRegisterTeam,
  },
  {
    path: '/landing-register-client',
    component: LandingRegisterWithClientImage,
  },
  {
    path: '/landing-register-picture',
    component: LandingRegisterWithPicture,
  },
  {
    path: '/lokal-providers',
    component: LokalCredentialsFlipCard,
  },
  {
    path: '/simulator',
    component: Simulator,
  },
  {
    path: '/simulator-register',
    component: SimulatorRegister,
  },
  {
    path: '/simulator-register-video',
    component: SimulatorRegisterVideo,
  },
  {
    path: '/simulator-register-campaign',
    component: SimulatorRegister,
  },
  {
    path: '/simulator-register-v2',
    component: SimulatorRegisterCaptcha,
  },
  {
    path: '/kobros-register',
    component: CollectionRegister,
  },
  {
    path: '/collection-register',
    component: KobrosLandingRegisterForm,
  },
  {
    path: '/evaluate',
    component: LandingNPS,
  },
  {
    path: '/evaluate-invoices',
    component: EvaluateInvoices,
  },
  {
    path: '/promo-operation',
    component: Churn,
  },
];

export default pilotRutes;
