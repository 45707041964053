import React from 'react';
import { Sidebar } from '@fingo/lib/components/layout';
import InvoiceProfile from '../views/App/supplierAutomate/InvoiceProfile';
import Profile from '../views/App/supplierAutomate/Profile';

const invoiceSubRoutes = [
  {
    id: 'document',
    label: 'Documento',
    path: 'document',
    icon: null,
    component: <Profile />,
    disabled: false,
    show: true,
  },
];

export const supplierAutomateRoutes = [
  {
    id: 'invoice',
    label: 'Invoice',
    path: '/app/invoice',
    component: <Sidebar goBack options={invoiceSubRoutes} />,
    disabled: false,
    show: true,
    showInNavbar: false,
    subRoutes: invoiceSubRoutes,
  },
];

export const invoiceTabs = [
  {
    id: 'profile',
    label: 'Documento',
    path: 'profile',
    component: <InvoiceProfile />,
    disabled: false,
    show: true,
  },
];
