import React from 'react';
import { FingoPaper, Sidebar } from '@fingo/lib/components/layout';

import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import BusinessOutlined from '@mui/icons-material/BusinessOutlined';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import SettingsSuggestOutlined from '@mui/icons-material/SettingsSuggestOutlined';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';

import { Info, Settings, Terms } from '../views/App/profile';
import { LoginLandingWithWave } from '../views';
import MyTerms from '../views/App/profile/MyTerms';
import MySettings from '../views/App/profile/MySettings';
import MyInfo from '../views/App/profile/MyInfo';
import Companies from '../views/App/profile/Companies';
import MyCompanies from '../views/App/profile/MyCompanies';
import Credentials from '../features/profile/pages/Credentials';

const credentialsTabs = [
  {
    id: 'my-credentials',
    label: 'Credenciales',
    path: 'credentials',
    component: <Credentials />,
    disabled: false,
    show: true,
  },
];

const profileSubRoutes = (selectedCompany) => [
  {
    id: 'profile-side-credentials',
    label: 'Mis credenciales',
    path: 'credentials',
    icon: <ArticleOutlined />,
    component: <FingoPaper tabs={credentialsTabs} />,
    disabled: false,
    show: !!selectedCompany,
    showInSidebar: true,
  },
  {
    id: 'profile-side-info',
    label: 'Mi información',
    path: 'info',
    icon: <PersonOutlineOutlined />,
    component: <Info />,
    disabled: false,
    show: !!selectedCompany,
    showInSidebar: true,
  },
  {
    id: 'profile-side-companies',
    label: 'Mis empresas',
    path: 'empresas',
    icon: <BusinessOutlined />,
    component: <Companies />,
    disabled: false,
    show: !!selectedCompany,
    showInSidebar: true,
  },
  {
    id: 'profile-side-settings',
    label: 'Configuraciones',
    path: 'settings',
    icon: <SettingsSuggestOutlined />,
    component: <Settings />,
    disabled: false,
    show: !!selectedCompany,
    showInSidebar: true,
  },
  {
    id: 'profile-side-terms',
    label: 'TyC',
    path: 'terms',
    icon: <StarBorderOutlined />,
    component: <Terms />,
    disabled: false,
    show: true,
    showInSidebar: true,
  },
  {
    id: 'profile-side-register',
    label: 'Terminar registro',
    path: 'register',
    icon: <PersonOutlineOutlined />,
    component: <LoginLandingWithWave />,
    disabled: false,
    show: !selectedCompany,
    showInSidebar: true,
  },
];

export const profileRoutes = (selectedCompany, isMobile) => [
  {
    id: 'profile',
    label: 'Perfil',
    path: '/app/profile',
    component: <Sidebar showLogOut options={profileSubRoutes(selectedCompany)} />,
    disabled: false,
    show: true,
    subRoutes: profileSubRoutes(selectedCompany),
    showInNavbar: isMobile,
  },
];

export const myCompaniesTabs = [
  {
    id: 'my-companies',
    label: 'Mis empresas',
    path: 'my-companies',
    component: <MyCompanies />,
    disabled: false,
    show: true,
  },
];

export const infoTabs = [
  {
    id: 'my-profile',
    label: 'Mi información',
    path: 'my-profile',
    component: <MyInfo />,
    disabled: false,
    show: true,
  },
];

export const settingsTabs = [
  {
    id: 'my-settings',
    label: 'Configuraciones',
    path: 'my-settings',
    component: <MySettings />,
    disabled: false,
    show: true,
  },
];

export const termsTabs = [
  {
    id: 'my-terms',
    label: 'Términos',
    path: 'my-terms',
    component: <MyTerms />,
    disabled: false,
    show: true,
  },
];
