import { useMutation } from '@apollo/client';
import { OTP_LOGIN } from '@fingo/lib/graphql';
import { useMemo } from 'react';

const useSendOtp = (params, email, onCompleted) => {
  const [otpLogin, { loading, error }] = useMutation(
    OTP_LOGIN,
    {
      variables: {
        clientId: params.clientId,
        email,
      },
      onCompleted,
    },
  );
  const errorMessage = useMemo(() => {
    if (error) {
      return 'Este usuario no ha sido registrado por Office Banking. Reintente usando el correo correcto.';
    }
    return '';
  }, [error]);
  return [otpLogin, loading, errorMessage];
};

export default useSendOtp;
