import React from 'react';
import Home from '../views/App/Home';
import { isRouteDisabled } from '../helpers/routes';

const homeRoutes = (user, showTyc) => [
  {
    id: 'nav-home',
    label: 'Home',
    path: '/app/home',
    component: <Home />,
    disabled: isRouteDisabled(user),
    show: user && !showTyc,
    showInNavbar: true,
    subRoutes: [],
  },
];

export default homeRoutes;
