import { useCallback, useMemo } from 'react';
import isNumber from 'lodash/isNumber';
import { formatMoneyWithSign } from '@fingo/lib/helpers';

const useInvoiceSimulator = ({ amount, _setAmount, _setDaysToPay }) => {
  const [maxAmount, minAmount] = [100000000, 0];
  const [maxDaysToPay, minDaysToPay] = [90, 15];

  const formatLabelMoney = useCallback((slideAmount) => `${formatMoneyWithSign(slideAmount)}`, []);
  const formatLabelDays = useCallback((slideDays) => `${slideDays} días`, []);

  const firstSegmentMaxValue = 10000000;
  const firstSegmentPercentage = 0.75;
  const firstSegmentMaxSlideValue = (maxAmount + minAmount) * firstSegmentPercentage;

  const amountScale = useCallback((value) => {
    if (value === minAmount || value === maxAmount) return value;
    if (value <= firstSegmentMaxSlideValue) {
      const scaledValue = value * (firstSegmentMaxValue / firstSegmentMaxSlideValue);
      return Math.floor(scaledValue);
    }
    const scaledValue = firstSegmentMaxValue
      + (maxAmount - firstSegmentMaxValue) * (
        (value - firstSegmentMaxSlideValue) / (maxAmount - firstSegmentMaxSlideValue)
      );
    return Math.floor(scaledValue);
  }, [firstSegmentMaxSlideValue, maxAmount, minAmount, firstSegmentMaxValue]);

  const amountUnscale = useCallback((scaledValue) => {
    const value = Number(scaledValue || 0);
    if (value === minAmount || value === maxAmount) return value;
    if (value < 100) return value;
    if (value <= firstSegmentMaxValue) {
      const unscaledValue = value * (firstSegmentMaxSlideValue / firstSegmentMaxValue);
      return Math.floor(unscaledValue);
    }
    const unscaledValue = (
      (value - firstSegmentMaxValue) / (maxAmount - firstSegmentMaxValue)
    ) * (maxAmount - firstSegmentMaxSlideValue) + firstSegmentMaxSlideValue;
    return Math.floor(unscaledValue);
  }, [firstSegmentMaxSlideValue, maxAmount, minAmount, firstSegmentMaxValue]);

  const setAmount = useCallback((event) => {
    const { target: { value } } = event;
    const cleanValue = Number(value.replace(/[^0-9]/g, ''));
    if (isNumber(cleanValue)) {
      _setAmount(Math.min(cleanValue, maxAmount));
    }
  }, [_setAmount, maxAmount]);

  const sliderAmount = useMemo(() => amountUnscale(amount), [amount, amountUnscale]);

  const setSliderAmount = useCallback((event) => {
    const { target: { value } } = event;
    const cleanValue = Number(value);
    const scaledValue = amountScale(cleanValue);
    _setAmount(scaledValue);
  }, [_setAmount, amountScale]);

  const showSliderAmount = useCallback(() => amount, [amount]);

  const setDaysToPay = useCallback((event) => {
    const { value } = event.target;
    if (value === '') {
      _setDaysToPay({ target: { value: 0 } });
    } else {
      _setDaysToPay({ target: { value: parseInt(value, 10) } });
    }
  }, [_setDaysToPay]);

  return {
    formatLabelMoney,
    formatLabelDays,
    sliderAmount,
    setAmount,
    setSliderAmount,
    showSliderAmount,
    setDaysToPay,
    maxAmount,
    minAmount,
    maxDaysToPay,
    minDaysToPay,
  };
};

export default useInvoiceSimulator;
